<template>
  <div class="mm-main__desc">
    <div class="mm-main__desc-title">
      <h1>
        B2B-маркетплейс быстрых закупок
      </h1>
      <div class="mm-main__desc-caption">
        АО «В2В Трэйд» — ИТ компания, обеспечивающая разработку платформы маркетплейса Максмарт
      </div>
    </div>
    <div class="mm-main__desc-text-wrap">
      <div
        class="expand-div"
        :class="{expanded: isExpanded}"
      >
        <p>
          B2B-маркетплейс Максмарт – инновационный для российского рынка маркетплейс быстрых корпоративных закупок стандартизованной (long-tail) номенклатуры, который оптимизирует <span
            v-if="!isExpanded"
            class="visible-tablet"
          >...</span> закупочный процесс для крупных промышленных, строительных компаний и бизнеса любого масштаб<span
            v-if="!isExpanded"
            class="visible-mobile"
          >... </span>а. Преимуществом Максмарт по сравнению с другими электронными торговыми площадками является обеспечение полного цикла сделки от получения потребности от покупателя до автоматического формирования корзины на основании мэппинга номенклатуры покупателя и маркетплейса, автоматического выставления документов на входящую поставку и поставки в базис получателя.
        </p>

        <p>B2B-площадка обеспечивает автоматизацию процесса корпоративных закупок и оцифровывает производителей, обеспечивает консолидацию заказов и оптовые поставки до покупателя. </p>

        <p>
          Маркетплейс быстрых оптовых закупок Максмарт обеспечивает поставщикам и производителям продукции выход в интернет-пространство и доступ к широкому рынку бизнес-клиентов, экономя время и ресурсы.
        </p>
      </div>

      <button
        class="btn btn-text"
        @click="isExpanded = !isExpanded"
      >
        {{ isExpanded ? 'Свернуть' : 'Показать далее' }}
      </button>
    </div>
    <div class="mm-main__desc-img-wrap">
      <BaseImg
        src="/images/main-desc-img.svg"
        format="webp"
        :width="160"
        :height="160"
      />
      <div class="mm-main__desc-h2">
        Лучший маркетплейс по версии Tagline Awards 2022
      </div>
    </div>
  </div>
</template>

<script setup lang='ts'>
  const isExpanded  = ref<boolean>(false);
</script>

<style lang="scss">
@import 'styles/base/common/variables';

.mm-main {
  &__desc {
    display: flex;
    border: 1px solid $gray-200;
    border-radius: 8px;
    padding: 40px 32px 44px;
    margin-bottom: 8px;

    &-title {
      h1 {
        font-weight: 500;
        font-size: 32px;
        line-height: 36px;
        color: $text-black;
        width: 338px;
        flex: 0 0 338px;
        margin-right: 44px;
        margin-bottom: 0;
      }
    }

    &-caption {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $text-light-green;
      margin-top: 20px;
      width: 264px;
    }

    &-text-wrap {
      width: 755px;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      color: $text-light-green;
      margin-bottom: 32px;

      span {
        display: none;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .btn {
        display: none;
      }

    &-img-wrap {
      margin-left: auto;

      img {
        margin-bottom: 16px;
      }
    }

    &-h2 {
      width: 240px;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: $text-dark-green;
    }
  }
}

@media only screen and (max-width: 1599px) {
  .mm-main {
    &__desc {
      padding: 32px 24px;

      h1 {
        width: 298px;
        flex: 0 0 298px;
        margin-right: 65px;
      }

      &-text-wrap {
        flex: 0 0 601px;
        margin-right: 56px;
      }

      &-h2 {
        width: 196px;
      }
    }
  }
}

@media only screen and (max-width: 1279px) {
  .mm-main {
    &__desc {
      padding: 32px 16px;
      flex-direction: column;
      margin: 4px;
      position: relative;
      padding-bottom: 60px;
      margin-bottom: 124px;

      h1 {
        width: auto;
        flex: 0 0 auto;
        margin-right: 0;
        font-size: 24px;
        line-height: 32px;
        color: $text-black;
      }

      &-caption {
        margin-top: 4px;
        width: auto;
      }

      &-text-wrap {
        flex: 0 0 auto;
        width: auto;
        margin-top: 24px;
      }

      p {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 24px;

        span {
          display: inline;
        }
      }

      .expand-div {
        max-height: 43px;
        overflow: hidden;
        transition: max-height .3s;

        &.expanded {
          max-height: 500px;
        }
      }

      .btn {
        padding: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin-top: 8px;
        display: block;
      }

      &-img-wrap {
        display: flex;
        align-items: center;
        position: absolute;
        height: 116px;
        border: 1px solid $gray-200;
        border-radius: 8px;
        width: 100%;
        left: 0;
        bottom: -120px;
        padding: 20px 16px;

        img {
          height: 76px;
          width: 76px;
          margin-right: 24px;
          margin-bottom: 0;
        }

        h2 {
          font-size: 16px;
          line-height: 24px;
          width: 228px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .mm-main {
    &__desc {
      padding: 32px 16px;

      p {
        font-size: 12px;
        line-height: 16px;
      }

      &-text-wrap {
        width: 100%;
      }

      &-img-wrap {
        .mm-main__desc-h2 {
          width: auto;
        }
      }

      .expand-div {
        max-height: 93px;

        &.expanded {
          max-height: 700px;
        }
      }
    }
  }
}
</style>
