import { IImagesWallItem } from 'shared/models/imagesWall.model';

export const MAIN_BRANDS_IMAGES: Array<IImagesWallItem> = [
  {
    src: '/images/brands/garvin-brand-2x.png',
    srcset: '/images/brands/garvin-brand.png 1x, /images/brands/garvin-brand-2x.png 2x',
  },
  {
    src: '/images/brands/tech-krep-brand-2x.png',
    srcset: '/images/brands/tech-krep-brand.png 1x, /images/brands/tech-krep-brand-2x.png 2x',
  },
  {
    src: '/images/brands/ecoplast-brand-2x.png',
    srcset: '/images/brands/ecoplast-brand.png 1x, /images/brands/ecoplast-brand-2x.png 2x',
  },
  {
    src: '/images/brands/asimeto-brand-2x.png',
    srcset: '/images/brands/asimeto-brand.png 1x, /images/brands/asimeto-brand-2x.png 2x',
  },
  {
    src: '/images/brands/bestar-brand-2x.png',
    srcset: '/images/brands/bestar-brand.png 1x, /images/brands/bestar-brand-2x.png 2x',
  },
  {
    src: '/images/brands/bort-brand-2x.png',
    srcset: '/images/brands/bort-brand.png 1x, /images/brands/bort-brand-2x.png 2x',
  },
  {
    src: '/images/brands/brabix-brand-2x.png',
    srcset: '/images/brands/brabix-brand.png 1x, /images/brands/brabix-brand-2x.png 2x',
  },
  {
    src: '/images/brands/laima-brand-2x.png',
    srcset: '/images/brands/laima-brand.png 1x, /images/brands/laima-brand-2x.png 2x',
  },
  {
    src: '/images/brands/brauberg-brand-2x.png',
    srcset: '/images/brands/brauberg-brand.png 1x, /images/brands/brauberg-brand-2x.png 2x',
  },
  {
    src: '/images/brands/deko-brand-2x.png',
    srcset: '/images/brands/deko-brand.png 1x, /images/brands/deko-brand-2x.png 2x',
  },
  {
    src: '/images/brands/galant-brand-2x.png',
    srcset: '/images/brands/galant-brand.png 1x, /images/brands/galant-brand-2x.png 2x',
  },
  {
    src: '/images/brands/glanzen-brand-2x.png',
    srcset: '/images/brands/glanzen-brand.png 1x, /images/brands/glanzen-brand-2x.png 2x',
  },
  {
    src: '/images/brands/techrim-brand-2x.png',
    srcset: '/images/brands/techrim-brand.png 1x, /images/brands/techrim-brand-2x.png 2x',
  },
  {
    src: '/images/brands/licota-brand-2x.png',
    srcset: '/images/brands/licota-brand.png 1x, /images/brands/licota-brand-2x.png 2x',
  },
  {
    src: '/images/brands/mystery-brand-2x.png',
    srcset: '/images/brands/mystery-brand.png 1x, /images/brands/mystery-brand-2x.png 2x',
  },
  {
    src: '/images/brands/norgau-brand-2x.png',
    srcset: '/images/brands/norgau-brand.png 1x, /images/brands/norgau-brand-2x.png 2x',
  },
  {
    src: '/images/brands/pifagor-brand-2x.png',
    srcset: '/images/brands/pifagor-brand.png 1x, /images/brands/pifagor-brand-2x.png 2x',
  },
  {
    src: '/images/brands/cutop-brand-2x.png',
    srcset: '/images/brands/cutop-brand.png 1x, /images/brands/cutop-brand-2x.png 2x',
  },
  {
    src: '/images/brands/rucelft-brand-2x.png',
    srcset: '/images/brands/rucelft-brand.png 1x, /images/brands/rucelft-brand-2x.png 2x',
  },
  {
    src: '/images/brands/runtec-brand-2x.png',
    srcset: '/images/brands/runtec-brand.png 1x, /images/brands/runtec-brand-2x.png 2x',
  },
  {
    src: '/images/brands/specx-brand-2x.png',
    srcset: '/images/brands/specx-brand.png 1x, /images/brands/specx-brand-2x.png 2x',
  },
  {
    src: '/images/brands/staff-brand-2x.png',
    srcset: '/images/brands/staff-brand.png 1x, /images/brands/staff-brand-2x.png 2x',
  },
  {
    src: '/images/brands/tdm-electric-brand-2x.png',
    srcset: '/images/brands/tdm-electric-brand.png 1x, /images/brands/tdm-electric-brand-2x.png 2x',
  },
  {
    src: '/images/brands/fit-brand-2x.png',
    srcset: '/images/brands/fit-brand.png 1x, /images/brands/fit-brand-2x.png 2x',
  },
  {
    src: '/images/brands/zitrek-brand-2x.png',
    srcset: '/images/brands/zitrek-brand.png 1x, /images/brands/zitrek-brand-2x.png 2x',
  },
  {
    src: '/images/brands/matrix-brand-2x.png',
    srcset: '/images/brands/matrix-brand.png 1x, /images/brands/matrix-brand-2x.png 2x',
  },
  {
    src: '/images/brands/sparta-brand-2x.png',
    srcset: '/images/brands/sparta-brand.png 1x, /images/brands/sparta-brand-2x.png 2x',
  },
  {
    src: '/images/brands/gross-brand-2x.png',
    srcset: '/images/brands/gross-brand.png 1x, /images/brands/gross-brand-2x.png 2x',
  },
  {
    src: '/images/brands/bars-brand-2x.png',
    srcset: '/images/brands/bars-brand.png 1x, /images/brands/bars-brand-2x.png 2x',
  },
  {
    src: '/images/brands/palisad-brand-2x.png',
    srcset: '/images/brands/palisad-brand.png 1x, /images/brands/palisad-brand-2x.png 2x',
  },
  {
    src: '/images/brands/stern-brand-2x.png',
    srcset: '/images/brands/stern-brand.png 1x, /images/brands/stern-brand-2x.png 2x',
  },
  {
    src: '/images/brands/sibrtech-brand-2x.png',
    srcset: '/images/brands/sibrtech-brand.png 1x, /images/brands/sibrtech-brand-2x.png 2x',
  },
  {
    src: '/images/brands/kronwerk-brand-2x.png',
    srcset: '/images/brands/kronwerk-brand.png 1x, /images/brands/kronwerk-brand-2x.png 2x',
  },
  {
    src: '/images/brands/denzel-brand-2x.png',
    srcset: '/images/brands/denzel-brand.png 1x, /images/brands/denzel-brand-2x.png 2x',
  },
  {
    src: '/images/brands/elfe-brand-2x.png',
    srcset: '/images/brands/elfe-brand.png 1x, /images/brands/elfe-brand-2x.png 2x',
  },
  {
    src: '/images/brands/stels-brand-2x.png',
    srcset: '/images/brands/stels-brand.png 1x, /images/brands/stels-brand-2x.png 2x',
  },
  {
    src: '/images/brands/kurs-brand-2x.png',
    srcset: '/images/brands/kurs-brand.png 1x, /images/brands/kurs-brand-2x.png 2x',
  },
];
