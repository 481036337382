<template>
  <div
    class="mm-main"
    :class="{ logged: isSearchGroupAccess }"
  >
    <section class="mm-main__slider">
      <Carousel
        ref="mainCarouselRef"
        :items-to-show="1"
        :wrap-around="true"
        :autoplay="5000"
        @update:model-value="onUpdateSlide"
      >
        <Slide
          v-for="slideItem in mainCarouselSlides"
          :key="slideItem.id"
        >
          <div class="slider__item">
            <div class="item__count">
              {{ slideItem.id }} из {{ MAIN_SLIDE_ITEMS.length }}
            </div>
            <div class="item__title">
              {{ slideItem.title }}
            </div>
            <div class="item__subtitle">
              {{ slideItem.subtitle }}
            </div>
            <button
              v-if="slideItem.isDarkSlide"
              :key="slideItem.buttonText"
              class="btn btn-secondary"
              @touchend.prevent="onSupplierRegistration"
              @click="onSupplierRegistration"
            >
              {{ slideItem.buttonText }}
              <SvgIcon src="navigation/main-arrow" />
            </button>
            <button
              v-else
              :key="slideItem.buttonText"
              class="btn btn-primary"
              @touchend.prevent="onShowVideoAboutModal"
              @click="onShowVideoAboutModal"
            >
              {{ slideItem.buttonText }}
              <SvgIcon src="action/video-icon-white" />
            </button>
            <div class="item__image">
              <BaseImg
                :src="slideItem.img"
                alt=""
                format="webp"
                class="item__cover"
              />
            </div>
            <div class="item__caption">
              АО «В2В Трэйд» — ИТ компания, обеспечивающая разработку платформы маркетплейса Максмарт
            </div>
          </div>
        </Slide>

        <template #addons>
          <navigation />
        </template>
      </Carousel>
    </section>

    <section class="mm-main__features">
      <div class="features__title-wrap">
        <div class="features__title">
          Делаем закупки <br class="visible-small-desktop"> быстрее <br class="visible-mobile"> и проще
        </div>
        <p class="features__subtitle">
          Улучшение закупочных процессов <br class="visible-small-desktop visible-mobile"> стандартизированной номенклатуры <br class="visible-small-desktop visible-tablet visible-mobile"> с выгодой для всех участников
        </p>
      </div>
      <div class="features__list-wrap">
        <div class="features__list-title">
          Для крупных промышленных<br class="visible-small-desktop visible-tablet visible-mobile"> компаний, холдингов<br class="visible-tablet"> и корпораций
        </div>
        <ol class="features__list">
          <li class="features__item">
            Повышаем операционную<br class="visible-small-desktop visible-tablet visible-mobile"> эффективность за счет цифровизации
          </li>
          <li class="features__item">
            Снижаем стоимость закупок за счет <br class="visible-small-desktop visible-tablet visible-mobile"> консолидации
          </li>
          <li class="features__item">
            Значительно расширяем ассортимент <br class="visible-small-desktop visible-mobile"> и <br class="visible-tablet">количество поставщиков
          </li>
        </ol>
        <button
          class="btn btn-primary"
          @click="onClientCreateClick"
        >
          Стать покупателем
          <SvgIcon src="navigation/main-arrow" />
        </button>
      </div>

      <div class="features__list-wrap">
        <div class="features__list-title">
          Для дистрибьютеров,<br class="visible-small-desktop visible-tablet"> производителей<br class="visible-tablet visible-mobile"> и поставщиков
        </div>
        <ol class="features__list">
          <li class="features__item">
            Обеспечиваем прямой выход на закупки <br class="visible-small-desktop visible-tablet visible-mobile"> крупнейших корпоративных клиентов
          </li>
          <li class="features__item">
            Упрощаем работу: 1 договор, <br class="visible-mobile"> 1<br class="visible-small-desktop visible-tablet"> квалификация, электронный <br class="visible-tablet visible-mobile"> документооборот
          </li>
          <li class="features__item">
            Помогаем в цифровизации контента<br class="visible-small-desktop visible-mobile"> и <br class="visible-tablet">работе в онлайн
          </li>
        </ol>
        <button
          class="btn btn-primary"
          @click="onSupplierRegistration"
        >
          Стать поставщиком
          <SvgIcon src="navigation/main-arrow" />
        </button>
      </div>
    </section>

    <section class="mm-main__video">
      <div class="video__title-wrap">
        <div class="video__title">
          Главное о возможностях<br class="visible-small-desktop visible-tablet visible-mobile"> маркетплейса за 3 минуты
        </div>
        <p class="video__subtitle">
          Посмотрите короткую видеопрезентацию <br class="visible-tablet visible-mobile"> сервиса — <br class="visible-small-desktop">это самый быстрый способ <br class="visible-tablet visible-mobile"> познакомиться с Максмарт
        </p>
        <button
          class="btn btn-secondary"
          @click="onShowVideoAboutModal"
        >
          Смотреть видеопрезентацию

          <BaseImg
            src="/images/main/video-icon.png"
            alt=""
            format="webp"
          />
        </button>
      </div>

      <div
        class="video__img"
        @click="onShowVideoAboutModal"
      >
        <picture>
          <source
            media="(max-width: 767px)"
            srcset="/images/main/video-img-360.png 1x"
          >
          <BaseImg
            src="/images/main/video-img.png"
            alt=""
            format="webp"
          />
        </picture>
      </div>
    </section>

    <section class="mm-main__users">
      <div class="users__title">
        Покупателям
      </div>
      <p class="users__subtitle">
        Предлагаем уникальный набор сервисов
      </p>
      <ul class="users__wrap buyers">
        <li class="users__item">
          <div class="item__img">
            <picture>
              <source
                media="(max-width: 767px)"
                srcset="/images/buyer-features-icon-1-768.png 1x, /images/buyer-features-icon-1-768@x2.png 2x"
              >
              <BaseImg
                src="/images/buyer-features-icon-1v2.png"
                srcset="/images/buyer-features-icon-1v2.png 1x, /images/buyer-features-icon-1-768@x2.png 2x"
                alt=""
                format="webp"
              />
            </picture>
          </div>
          <div class="item__wrap">
            <div class="item__title">
              Автоматизированные закупки из ERP
            </div>
            <p class="item__desc">
              Заказы формируются на основе  потребности благодаря бесшовной интеграции с ERP-системой покупателя
            </p>
          </div>
        </li>
        <li class="users__item">
          <div class="item__img">
            <picture>
              <source
                media="(max-width: 767px)"
                srcset="/images/buyer-features-icon-4-768.png 1x, /images/buyer-features-icon-4-768@x2.png 2x"
              >
              <BaseImg
                src="/images/buyer-features-icon-4v2.png"
                srcset="/images/buyer-features-icon-4v2.png 1x, /images/buyer-features-icon-4@x2.png 2x"
                format="webp"
              />
            </picture>
          </div>
          <div class="item__wrap">
            <div class="item__title">
              Контролируемая доставка до базиса получателя
            </div>
            <p class="item__desc">
              Максмарт осуществляет доставку заказов даже в отдаленные регионы
            </p>
          </div>
        </li>
        <li class="users__item">
          <div class="item__img">
            <picture>
              <source
                media="(max-width: 360px)"
                srcset="/images/icon-4-360.png 1x, /images/icon-4-360@2x.png 2x"
              >
              <source
                media="(max-width: 768px)"
                srcset="/images/icon-4-768.png"
              >
              <BaseImg
                src="/images/icon-4v2.png"
                alt=""
                format="webp"
              />
            </picture>
          </div>
          <div class="item__wrap">
            <div class="item__title">
              Индивидуальные цены от поставщиков
            </div>
            <p class="item__desc">
              Возможность закупки как по индивидуальным ценам от конкретных поставщиков, так и по всем предложениям на маркетплейсе
            </p>
          </div>
        </li>
        <li class="users__item">
          <div class="item__img">
            <picture>
              <source
                media="(max-width: 360px)"
                srcset="/images/icon-2-360.png 1x, /images/icon-2-360@2x.png 2x"
              >
              <source
                media="(max-width: 768px)"
                srcset="/images/icon-2-768.png"
              >
              <BaseImg
                src="/images/icon-2v2.png"
                alt=""
                format="webp"
              />
            </picture>
          </div>
          <div class="item__wrap">
            <div class="item__title">
              Электронный документооборот
            </div>
            <p class="item__desc">
              Экономия времени и денег на сопровождение процесса закупки
            </p>
          </div>
        </li>
        <li class="users__item">
          <div class="item__img">
            <picture>
              <source
                media="(max-width: 360px)"
                srcset="/images/icon-1-360.png 1x, /images/icon-1-360@2x.png 2x"
              >
              <source
                media="(max-width: 768px)"
                srcset="/images/icon-1-768.png"
              >
              <BaseImg
                src="/images/icon-1@2x.png"
                alt=""
                format="webp"
              />
            </picture>
          </div>
          <div class="item__wrap">
            <div class="item__title">
              Консалтинг и оптимизация процессов
            </div>
            <p class="item__desc">
              Не тратьте время и деньги на согласование документов, сопоставление номенклатур и проверку поставщиков. Делегируйте это нам — покупайте быстрее
            </p>
          </div>
        </li>
      </ul>
    </section>
    <section class="mm-main__users">
      <div class="users__title">
        Поставщикам
      </div>
      <p class="users__subtitle">
        Упрощаем работу с B2B-закупками
      </p>
      <ul class="users__wrap">
        <li class="users__item">
          <div class="item__img">
            <picture>
              <source
                media="(max-width: 767px)"
                srcset="/images/seller-feature-icon-1-768.png 1x, /images/seller-feature-icon-1-768@x2.png 2x"
              >
              <BaseImg
                format="webp"
                src="/images/seller-feature-icon-1.png"
                srcset="/images/seller-feature-icon-1.png 1x, /images/seller-feature-icon-1@x2.png 2x"
              />
            </picture>
          </div>
          <div class="item__wrap">
            <div class="item__title">
              Регулярные заказы от корпоративных клиентов
            </div>
            <p class="item__desc">
              Прямой выход на закупки крупнейших компаний
            </p>
          </div>
        </li>
        <li class="users__item">
          <div class="item__img">
            <picture>
              <source
                media="(max-width: 360px)"
                srcset="/images/icon-6-360.png 1x, /images/icon-6-360@2x.png 2x"
              >
              <source
                media="(max-width: 768px)"
                srcset="/images/icon-6-768.png"
              >
              <BaseImg
                src="/images/icon-6v2.png"
                alt=""
                format="webp"
              />
            </picture>
          </div>
          <div class="item__wrap">
            <div class="item__title">
              Простая логистика
            </div>
            <p class="item__desc">
              Доставляйте товары в распределительные центры маркетплейса. Консолидацию заказов и их доставку в удаленные базисы Максмарт берет на себя
            </p>
          </div>
        </li>
        <li class="users__item">
          <div class="item__img">
            <picture>
              <source
                media="(max-width: 767px)"
                srcset="/images/seller-feature-icon-4-768.png 1x, /images/seller-feature-icon-4-768@x2.png 2x"
              >
              <BaseImg
                format="webp"
                src="/images/seller-feature-icon-1.png"
                srcset="/images/seller-feature-icon-4.png 1x, /images/seller-feature-icon-4@x2.png 2x"
              />
            </picture>
          </div>
          <div class="item__wrap">
            <div class="item__title">
              Отсутствие тендерных процедур
            </div>
            <p class="item__desc">
              Единожды пройденная квалификация открывает доступ к заказам всех покупателей маркетплейса
            </p>
          </div>
        </li>
        <li class="users__item">
          <div class="item__img">
            <picture>
              <source
                media="(max-width: 767px)"
                srcset="/images/seller-feature-icon-2-768.png 1x, /images/seller-feature-icon-2-768@x2.png 2x"
              >
              <BaseImg
                format="webp"
                src="/images/seller-feature-icon-1.png"
                srcset="/images/seller-feature-icon-2.png 1x, /images/seller-feature-icon-2@x2.png 2x"
              />
            </picture>
          </div>
          <div class="item__wrap">
            <div class="item__title">
              Помощь в создании цифрового каталога
            </div>
            <p class="item__desc">
              Поможем создать электронный каталог ваших товаров, сопоставим ассортимент с номенклатурой покупателя и разместим его на маркетплейсе
            </p>
          </div>
        </li>
        <li class="users__item">
          <div class="item__img">
            <picture>
              <source
                media="(max-width: 360px)"
                srcset="/images/icon-2-360.png 1x, /images/icon-2-360@2x.png 2x"
              >
              <source
                media="(max-width: 768px)"
                srcset="/images/icon-2-768.png"
              >
              <BaseImg
                src="/images/icon-2v2.png"
                alt=""
                format="webp"
              />
            </picture>
          </div>
          <div class="item__wrap">
            <div class="item__title">
              Электронный документооборот
            </div>
            <p class="item__desc">
              Максмарт является единым контрагентом для всех заказов, что сокращает затраты на сопровождение заказа
            </p>
          </div>
        </li>
      </ul>
    </section>
    <section class="mm-main__with-us sellers">
      <div class="with-us__title">
        С нами работают:
      </div>
      <ul class="with-us__list">
        <li class="with-us__item">
          <picture>
            <source
              media="(max-width: 1279px)"
              srcset="/images/main/sellers-logo-1-768.png"
            >
            <BaseImg
              src="/images/main/sellers-logo-1.png"
              alt=""
              format="webp"
            />
          </picture>
        </li>
        <li class="with-us__item">
          <picture>
            <source
              media="(max-width: 1279px)"
              srcset="/images/main/sellers-logo-2-768.png"
            >
            <BaseImg
              src="/images/main/sellers-logo-2.png"
              alt=""
              format="webp"
            />
          </picture>
        </li>
        <li class="with-us__item">
          <picture>
            <source
              media="(max-width: 1279px)"
              srcset="/images/main/sellers-logo-3-768.png"
            >
            <BaseImg
              src="/images/main/sellers-logo-3.png"
              alt=""
              format="webp"
            />
          </picture>
        </li>
        <li class="with-us__item">
          <picture>
            <source
              media="(max-width: 1279px)"
              srcset="/images/main/sellers-logo-4-768.png"
            >
            <BaseImg
              src="/images/main/sellers-logo-4.png"
              alt=""
              format="webp"
            />
          </picture>
        </li>
      </ul>
      <button
        class="btn btn-text"
        @click="onSupplierRegistration"
      >
        <SvgIcon src="action/plus" />
        Стать поставщиком
      </button>
    </section>

    <DemandedNeeds />

    <section class="mm-main__catalog">
      <div class="catalog__title">
        Каталог товаров
      </div>
      <p class="catalog__subtitle">
        Полноценный каталог товаров доступен <br class="visible-mobile"> сразу после заключения договора
      </p>

      <div class="catalog__wrap">
        <ul class="catalog__list">
          <li class="catalog__item">
            <div class="catalog__img">
              <BaseImg
                src="/images/main/catalog-1.png"
                alt=""
                format="webp"
              />
            </div>
            <div class="catalog__desc">
              Бытовая и компьютерная <br class="visible-tablet visible-mobile"> техника, мебель
            </div>
          </li>
          <li class="catalog__item hidden-mobile">
            <div class="catalog__img">
              <BaseImg
                src="/images/main/catalog-2.png"
                alt=""
                format="webp"
              />
            </div>
            <div class="catalog__desc">
              Расходные материалы
            </div>
          </li>
          <li class="catalog__item">
            <div class="catalog__img">
              <BaseImg
                src="/images/main/catalog-3.png"
                alt=""
                format="webp"
              />
            </div>
            <div class="catalog__desc">
              Строительное <br class="visible-tablet"> оборудование <br class="visible-small-desktop visible-tablet visible-mobile"> и материалы
            </div>
          </li>
          <li class="catalog__item hidden-mobile">
            <div class="catalog__img">
              <BaseImg
                src="/images/main/catalog-4.png"
                alt=""
                format="webp"
              />
            </div>
            <div class="catalog__desc">
              Аппаратура наблюдения <br class="visible-tablet"> и контроля
            </div>
          </li>
          <li class="catalog__item">
            <div class="catalog__img">
              <BaseImg
                src="/images/main/catalog-5.png"
                alt=""
                format="webp"
              />
            </div>
            <div class="catalog__desc">
              Хозяйственные,<br class="visible-tablet visible-mobile"> канцелярские товары <br class="visible-small-desktop visible-tablet visible-mobile"> и инвентарь
            </div>
          </li>
          <li class="catalog__item hidden-mobile">
            <div class="catalog__img">
              <BaseImg
                src="/images/main/catalog-6.png"
                alt=""
                format="webp"
              />
            </div>
            <div class="catalog__desc">
              Электросварочное <br class="visible-small-desktop visible-tablet"> и газопламенное<br class="visible-tablet"> оборудование
            </div>
          </li>
          <li class="catalog__item">
            <div class="catalog__img">
              <BaseImg
                src="/images/main/catalog-7.png"
                alt=""
                format="webp"
              />
            </div>
            <div class="catalog__desc">
              Инструменты и<br class="visible-tablet"> спецоснастка
            </div>
          </li>
          <li class="catalog__item hidden-mobile">
            <div class="catalog__img">
              <BaseImg
                src="/images/main/catalog-8.png"
                alt=""
                format="webp"
              />
            </div>
            <div class="catalog__desc">
              Кабельно-проводниковая<br class="visible-tablet"> продукция
            </div>
          </li>
          <li class="catalog__item">
            <div class="catalog__img">
              <BaseImg
                src="/images/main/catalog-9.png"
                alt=""
                format="webp"
              />
            </div>
            <div class="catalog__desc">
              Электротехнические<br class="visible-tablet visible-mobile"> материалы
            </div>
          </li>
          <li class="catalog__item hidden-mobile">
            <div class="catalog__img">
              <BaseImg
                src="/images/main/catalog-10.png"
                alt=""
                format="webp"
              />
            </div>
            <div class="catalog__desc">
              АКБ, ЗВУ, инструменты<br class="visible-tablet"> для ремонта шин
            </div>
          </li>

          <li class="catalog__item">
            <div class="catalog__img">
              <BaseImg
                src="/images/main/catalog-11.png"
                alt=""
                format="webp"
              />
            </div>
            <div class="catalog__desc">
              Спецодежда и обувь
            </div>
          </li>

          <li class="catalog__item">
            <BaseImg
              src="/images/main/catalog-12.png"
              alt=""
              format="webp"
            />
            <div class="catalog__desc">
              Более 3 000<br class="visible-tablet visible-mobile"> товарных категорий<br class="visible-tablet visible-mobile">
              в каталоге
            </div>
          </li>
        </ul>
      </div>
    </section>
    <section class="mm-main__brands">
      <div class="brands__title">
        Бренды на маркетплейсе
      </div>
      <ClientOnly>
        <LazyImagesWall :images="MAIN_BRANDS_IMAGES" />
      </ClientOnly>
    </section>
    <section
      v-if="news?.items?.length"
      class="mm-main__news"
    >
      <div class="news__title">
        Новости Максмарт
        <button
          class="btn btn-text"
          @click="onGoToAllNews"
        >
          Все новости
          <SvgIcon :src="EIconPath.NavigationMainArrowSvg" />
        </button>
      </div>
      <ul class="news__list">
        <li
          v-for="(newsItem, index) in news?.items"
          :key="index"
          class="news__item"
          @click="onNewsItemClick(newsItem)"
        >
          <div
            class="item__logo"
            :class="{ 'no-photo' : !newsService.getImage(newsItem.image) }"
          >
            <BaseImg
              :src="newsService.getImage(newsItem.image) || 'images/banner-no-items-icons.svg'"
              alt=""
              format="webp"
              loading="lazy"
            />
          </div>
          <div
            class="item__title"
            v-html="sanitize(newsItem.title)"
          />
          <div class="item__tags">
            <span
              class="item__name"
              @click="onNewsTagClick(newsItem)"
            >
              {{ newsItem.shortSourceUrl ? newsItem.shortSourceUrl : newsItem.tags?.[0]?.label }}
            </span>
            <span
              v-if="newsItem.publicationDate"
              class="item__name"
            >
              {{ DateFormat.default(newsItem.publicationDate) }}
            </span>
          </div>
        </li>
      </ul>
    </section>

    <PagesMainPageDescription />
  </div>
</template>

<script lang="ts" setup>
import { ELayout } from 'enums/layouts.enum';
import VideoAboutModal from 'components/modals/VideoAboutModal.vue';
import ModalManager from 'shared/services/modalManager.service';
import BaseImg from 'shared/components/BaseImg.vue';
import PagesMainPageDescription from 'components/pages/MainPageDescription.vue';
import { useSearchGroupAccess } from 'composables/useGroup';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import { MAIN_SLIDE_ITEMS } from 'constants/pages/main/mainSlideItems.const';
import { MAIN_BRANDS_IMAGES } from 'constants/pages/main/mainBrandsImages.const';
import { MainPageCarouselHeaderThemeService } from 'services/main-page/mainPageCarouselHeaderTheme.service';
import { sanitize } from 'shared/directives/sanitize';
import { NewsApiService } from 'services/api/newsApi.service';
import { NEWS_SECTION_ALL_ID } from 'constants/pages/news.const';
import { IStrapiNewsListResponse } from 'models/strapi/strapiNews.model';
import { IBasePagedResponse } from 'shared/models/baseApi.model';
import Loader from 'shared/utils/loaderHelper.util';
import SvgIcon from 'shared/components/SvgIcon.vue';
import { DateFormat } from 'shared/utils/dateFormat.util';
import { NewsService } from 'services/news/news.service';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { useRequestUrl } from 'composables/useRequestUrl';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import DemandedNeeds from 'components/DemandedNeeds.vue';
import { useRedirectMirror } from 'composables/useRedirectMirror';
import { EClientApprovalType } from 'enums/client/clientApprovalType.enum';
import { EIconPath } from 'shared/enums/iconPath.enum';

await useRedirectMirror();

definePageMeta({
  layout: ELayout.TransparentHead,
});

useHead({
  title: 'B2B электронная торговая площадка, Б2Б маркетплейс быстрых оптовых закупок - Максмарт',
  meta: [
    { name: 'google-site-verification', content: useRuntimeConfig()?.public?.googleSiteVerificationToken },
    { name: 'yandex-verification', content: useRuntimeConfig()?.public?.yandexVerificationKey },
    {
      name: 'description',
      content: 'Максмарт - B2B электронная торговая площадка, маркетплейс быстрых закупок. Оптимизация закупок для крупнейших промышленных компаний, снижение стоимости закупок, широкий ассортимент, логистика до базиса клиента.',
    },
  ],
});


const isSearchGroupAccess = useSearchGroupAccess();
useShowRedirectNotification();

const videoAboutModalName = 'videoAboutModal';
const modalManager = process.client ? inject<ModalManager>(ModalManager.getServiceName()) : undefined;
const isMounted = ref<boolean>(false);
const mainCarouselRef = ref<InstanceType<Carousel>>();
const mainCarouselSlides = computed(() => !isMounted.value ? MAIN_SLIDE_ITEMS.slice(0, 1) : MAIN_SLIDE_ITEMS);
const news = ref<IBasePagedResponse<IStrapiNewsListResponse>>();
const themeService = inject<MainPageCarouselHeaderThemeService>(MainPageCarouselHeaderThemeService.getServiceName());
const isNewsLoading = Loader.getReactiveInstance();
const newsService = new NewsService(useRequestUrl());

const { onSupplierRegistration } = useSupplierRegistration();

const { onCLickClientApprovalModal } = useClientApproval();

function onUpdateSlide(index: number) {
  const carouselSlides = mainCarouselSlides.value;
  const prevSliderIndex = index === 0 ? carouselSlides?.length - 1 : index - 1;
  themeService?.setDarkTheme(carouselSlides?.[prevSliderIndex]?.isDarkSlide);
}

function onShowVideoAboutModal(): void {
  modalManager?.show({
    component: VideoAboutModal,
    bind: {
      name: videoAboutModalName,
    },
    on: {
      close() {
        modalManager.hide(videoAboutModalName);
      },
      'update:modelValue'() {
        modalManager.hide(videoAboutModalName);
      },
    },
  });
}

function onNewsItemClick(item: IStrapiNewsListResponse) {
  if (item.shortSourceUrl) {
    window.open(sanitizeUrl(item.sourceUrl), '_blank');
    return;
  }

  navigateTo(`/news/${item.id}`);
}

function onNewsTagClick(item: IStrapiNewsListResponse) {
  if (item.shortSourceUrl) {
    window.open(sanitizeUrl(item.sourceUrl), '_blank');
    return;
  }

  navigateTo(`/news/?categoryId=${item.tags?.[0]?.id}`);
}

function onClientCreateClick() {
  onCLickClientApprovalModal(EClientApprovalType.Client);
}

function onGoToAllNews() {
  navigateTo('/news/');
}

async function loadNews() {
  try {
    isNewsLoading.activate();
    news.value = await NewsApiService.getNews({
      pageSize: 4,
      page: 1,
      sectionId: NEWS_SECTION_ALL_ID,
      mainPage: true,
    });
    isNewsLoading.deactivate();
  } catch (error) {
    clientSentry.captureException(error);
  }
}

onMounted(async () => {
  isMounted.value = true;
  mainCarouselRef.value?.restartCarousel();
  await loadNews();
});
</script>

<style scoped lang="scss">
@import 'shared/assets/styles/base/common/variables';

.mm-main {
  max-width: 1584px;
  width: calc(100% - 16px);
  margin: 0 auto;

  &.logged {
    .mm-main__slider {
      margin-top: -169px;

      .slider__item {
        padding-top: 181px;
        height: 603px;
      }
    }
  }

  &__slider {
    margin-top: -92px;
    border-radius: 8px;
    position: relative;

    :deep(.carousel) {
      .carousel__viewport {
        border-radius: 8px;
      }

      .carousel__track {
        transform: none !important;
        transition: none !important;
        list-style-type: none;
      }

      .carousel__prev,
      .carousel__next {
        position: absolute;
        top: 100%;
        left: auto;
        transform: translate(-32px, -80px);
        right: 0;
        background: $main-slider-conrols-bg;
        height: 40px;
        width: 60px;
      }

      .carousel__prev {
        right: 60px;
        border-radius: 6px 0 0 6px;

        &::before {
          content: "";
          position: absolute;
          border-right: 1px solid $main-slider-conrols-bc;
          height: 24px;
          right: 0;
        }
      }

      .carousel__next {
        border-radius: 0 6px 6px 0;
      }

      .carousel__slide {
        justify-content: flex-start;
        align-items: flex-start;

        .slider__item {
          will-change: background-image;
          transition: background-image 1s ease;

          .item__count,
          .item__title,
          .item__subtitle,
          .btn,
          .item__image,
          .item__caption {
            opacity: 0;
            transition: opacity 1s ease;
            will-change: opacity;
          }

          .item__image {
            height: 485px;
            width: max-content;
          }

          .item__cover {
            width: 100%;
            height: 100%;
          }
        }

        &--prev {
          opacity: 0;
          order: 1 !important;

          .slider__item {
            background: transparent;

            .item__count,
            .item__title,
            .item__subtitle,
            .btn,
            .item__image,
            .item__caption {
              opacity: 0;
            }
          }
        }

        &.carousel__slide--active,
        &.carousel__slide--visible {
          opacity: 1;
          order: 0 !important;

          .item__count,
          .item__title,
          .item__subtitle,
          .btn,
          .item__image,
          .item__caption {
            opacity: 1;
          }
        }

        & * {
          text-align: left;
        }

        &:first-child {
          background-image: linear-gradient(
                          107.81deg,
                          #ececec 0%,
                          #bcc6c3 95.12%
          );
        }

        &:nth-child(2) {
          .slider__item {
            background: linear-gradient(
                            81.46deg,
                            #02140e 18.86%,
                            #053b3f 73.91%
            );

            .item__title {
              color: $white;
              width: 532px;
            }

            .item__subtitle {
              color: $text-white-6;
              width: 367px;
            }

            .item__caption {
              color: $text-white-6;
            }
          }
        }
      }

      .slider__item {
        width: 100%;
        padding-top: 158px;
        padding-left: 32px;
        padding-bottom: 40px;
        height: 577px;
        border-radius: 8px;
        position: relative;
      }

      .item__count {
        font-size: 14px;
        line-height: 20px;
        color: $text-light-green;
        margin-bottom: 16px;
      }

      .item__title {
        font-weight: 500;
        font-size: 48px;
        line-height: 48px;
        margin-bottom: 40px;
        width: 508px;
        color: $text-black;
      }

      .item__subtitle {
        font-size: 16px;
        line-height: 24px;
        color: $text-dark-green;
        width: 491px;
      }

      .btn {
        height: 48px;
        padding: 14px 20px;
        position: absolute;
        bottom: 50px;
        font-weight: 500;

        &.btn-secondary {
          color: $text-black;
        }

        svg {
          margin-left: 12px;

          path + path {
            fill: $link;
          }
        }
      }

      .item__image {
        position: absolute;
        right: 0;
        bottom: 0;

        img {
          border-bottom-right-radius: 8px;
        }
      }

      .item__caption{
        position: absolute;
        bottom: 18px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: $text-light-green;
      }
    }
  }

  &__features {
    display: flex;
    border: 1px solid $gray-200;
    border-radius: 8px;
    padding: 40px 33px 50px;
    margin-bottom: 8px;
    background-image: url("shared/public/images/main/features-bg.png");
    background-repeat: no-repeat;
    background-position-y: -18px;
    margin-top: 8px;

    .features__title-wrap {
      margin-right: 94px;
    }

    .features__title {
      font-weight: 500;
      font-size: 48px;
      line-height: 48px;
      margin-bottom: 40px;
      width: 414px;
      color: $text-black;
    }

    .features__subtitle {
      font-size: 16px;
      line-height: 24px;
      color: $text-dark-green;
      width: 374px;
    }

    .features__list-wrap {
      margin-right: 62px;
      display: flex;
      flex-direction: column;

      & + .features__list-wrap {
        margin-right: 0;
      }

      .btn {
        margin-top: auto;
        width: 208px;
        font-weight: 500;
      }
    }

    .features__list-title {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 48px;
      width: 448px;
      color: $text-black;
    }

    .features__list {
      margin: 0;
      padding: 0;
      list-style-type: none;
      counter-reset: li;
    }

    .features__item {
      width: 374px;
      font-size: 16px;
      line-height: 24px;
      color: $text-dark-green;
      margin-bottom: 24px;

      &::before {
        counter-increment: li;
        content: counter(li, decimal-leading-zero);
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $link;
        margin-right: 8px;
      }

      &:last-child {
        margin-bottom: 64px;
      }
    }
  }

  &__video {
    background: linear-gradient(81.46deg, #02140e 18.86%, #053b3f 73.91%);
    border-radius: 8px;
    padding: 40px 32px;
    margin-bottom: 80px;
    position: relative;

    .video__title {
      font-weight: 500;
      font-size: 32px;
      line-height: 36px;
      color: $text-white;
      width: 501px;
      margin-bottom: 32px;
    }

    .video__subtitle {
      font-size: 16px;
      line-height: 24px;
      color: $text-white;
      opacity: 0.6;
      margin-bottom: 72px;
      width: 501px;
    }

    .btn {
      padding: 14px 20px;
      font-weight: 500;
      color: $text-black;

      img {
        margin-left: 12px;
      }
    }

    .video__img {
      position: absolute;
      top: 0;
      right: 32px;
      cursor: pointer;
    }
  }

  &__users {
    .users__title {
      font-weight: 500;
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 16px;
      padding-left: 32px;
      color: $text-black;
    }

    .users__subtitle {
      font-size: 16px;
      line-height: 24px;
      color: $text-dark-green;
      margin-bottom: 40px;
      padding-left: 32px;
    }

    .users__wrap {
      padding: 0;
      margin: 0;
      display: flex;
      list-style: none;

      &.buyers {
        margin-bottom: 80px;

        .users__item {
          border-bottom: 1px solid $gray-200;

          &:first-child {
            border-bottom-left-radius: 8px;
          }

          &:last-child {
            border-bottom-right-radius: 8px;
          }
        }
      }
    }

    .users__item {
      padding-right: 24px;
      border-top: 1px solid $gray-200;
      border-right: 1px solid $gray-200;
      flex: 0 0 20%;
      height: 415px;

      &:first-child {
        border-top-left-radius: 8px;
        border-left: 1px solid $gray-200;
      }

      &:last-child {
        border-top-right-radius: 8px;
      }
    }

    .item__img {
      width: 136px;
      height: 136px;
      margin-bottom: 16px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }

    .item__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: $text-dark-green;
      margin-bottom: 16px;
      padding-left: 32px;
    }

    .item__desc {
      font-size: 16px;
      line-height: 24px;
      color: $text-light-green;
      padding-left: 32px;
    }
  }

  &__with-us {
    background: $light-gray;
    display: flex;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 1px solid $gray-200;
    align-items: center;
    padding: 33px 32px;
    margin-bottom: 80px;

    .with-us__title {
      font-size: 20px;
      line-height: 28px;
      color: $text-dark-green;
      margin-bottom: 0;
      width: 285px;
      margin-right: 32px;
    }

    .with-us__list {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
      justify-content: space-between;
      flex: 1;
      margin-right: 80px;
    }

    .with-us__item {
      height: 48px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .btn {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      gap: 8px;
      padding-left: 0;
    }

    &.sellers {
      margin-bottom: 60px;

      .with-us__item {
        height: 34px;
      }
    }
  }

  &__catalog {
    margin-bottom: 80px;

    .catalog__title {
      font-weight: 500;
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 24px;
      padding-left: 32px;
      color: $text-black;
    }

    .catalog__subtitle {
      font-size: 16px;
      line-height: 24px;
      color: $text-dark-green;
      margin-bottom: 40px;
      padding-left: 32px;
    }

    .catalog__list {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      flex-wrap: wrap;
    }

    .catalog__item {
      flex: 0 0 33.3333%;
      display: flex;
      border-right: 1px solid $gray-200;
      border-bottom: 1px solid $gray-200;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: $text-dark-green;
      align-items: center;
      padding: 32px;

      img {
        margin-right: 32px;
      }

      .catalog__desc {
        width: 316px;
      }

      &:first-child:last-child {
        border-radius: 8px;
      }

      &:first-child {
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
        background: linear-gradient(107.81deg, #50d04d 0%, #00a77b 95.12%);
        padding: 0;
        position: relative;
        padding-right: 32px;

        img {
          height: 145px;
          position: absolute;
          top: 0;
          left: 0;
        }

        .catalog__desc {
          margin-left: 144px;
          color: $white;
          width: 225px;
        }

        .btn {
          margin-left: auto;
          background: $text-white-4;
          cursor: default;
          box-shadow: none;
        }
      }
    }
  }

  &__brands {
    margin-bottom: 80px;

    .brands__title {
      font-weight: 500;
      line-height: 36px;
      font-size: 32px;
      color: $text-black;
      padding-left: 32px;
      margin-bottom: 36px;
    }
  }

  &__news {
    margin-bottom: 120px;

    .news__title {
      font-weight: 500;
      font-size: 32px;
      line-height: 36px;
      padding-left: 32px;
      padding-right: 32px;
      margin-bottom: 32px;
      color: $text-black;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btn.btn-text {
        font-size: 16px;
        line-height: 24px;
        padding: unset;
      }
    }

    .news__list {
      list-style: none;
      margin: 0;
      padding: 0 32px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .news__item {
      flex: 0 0 calc(25% - 8px);
      cursor: pointer;
      max-width: 356px;

      .item__logo {
        height: 240px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        transform: rotate(180deg);
        margin-bottom: 16px;

        &.no-photo {
          background-color: $light-gray;
          border-radius: 80px 0px;
        }

        img {
          transform: rotate(-180deg);
          height: 240px;
          object-fit: cover;
          border-radius: 80px 0px;
        }
      }

      .item__title {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: $text-dark-green;
        margin-bottom: 16px;
      }

      .item__tags {
        display: flex;
        align-items: center;
        max-height: fit-content;
        flex-wrap: wrap;
      }

      .item__name {
        font-size: 14px;
        line-height: 20px;
        color: $text-light-green;
        text-decoration: none;

        &:not(:last-child) {
          margin-right: 8px;
          padding-right: 8px;
          border-right: 1px solid $dark-gray;
        }
      }

      .item__logo {
        img {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) {
  .mm-main__catalog {
    .catalog__item {
      &:nth-child(3n + 1) {
        border-left: 1px solid $gray-200;
      }

      &:nth-child(-n + 3) {
        border-top: 1px solid $gray-200;
      }

      &:nth-child(3n):last-child,
      &:nth-child(3n):nth-last-child(2),
      &:nth-child(3n):nth-last-child(3) {
        border-bottom-right-radius: 8px;
      }

      &:nth-child(3),
      &:nth-child(2):last-child {
        border-top-right-radius: 8px;
      }

      &:nth-child(3n-2):last-child,
      &:nth-child(3n-2):nth-last-child(2),
      &:nth-child(3n-2):nth-last-child(3) {
        border-bottom-left-radius: 8px;
      }
    }
  }
}

@media only screen and (max-width: 1599px) {
  .mm-main {
    &.logged {
      .mm-main__slider {
        :deep(.carousel) {
          .carousel__slide {
            &:nth-child(2) {
              .slider__item {
                background-position-y: 14px, 0;
              }
            }
          }
        }
      }
    }

    &__slider {
      :deep(.carousel) {
        .carousel__slide {
          .item__title {
            font-size: 40px;
            line-height: 44px;
            width: calc(100% - 832px);
          }

          .item__subtitle {
            width: calc(100% - 840px);
          }

          &:first-child {
            .item__image {
              background-image: url("shared/public/images/main/slider-item-1-1280.png");
              background-repeat: no-repeat;
              background-position-x: right, 0;
              background-position-y: bottom, 0;
              width: 832px;
              height: 485px;
              right: 0;
            }
          }

          &:nth-child(2) {
            .slider__item {
              background-image: url("shared/public/images/main/slider-item-2-1280.png"),
              linear-gradient(81.46deg, #02140e 18.86%, #053b3f 73.91%);
              background-repeat: no-repeat;
              background-position-x: right, 0;
              background-position-y: -14px, 0;
            }
          }
        }

        .slider__item {
          padding-left: 24px;
        }

        .item__image {
          right: 24px;

          img {
            display: none;
          }
        }

        .slider__control {
          right: 24px;
        }
      }
    }

    &__features {
      padding: 32px 24px 38px;
      background-position-y: -18px;

      .features__title-wrap {
        margin-right: 17px;
      }

      .features__title {
        width: 391px;
        font-size: 40px;
        line-height: 44px;
      }

      .features__subtitle {
        width: 348px;
      }

      .features__list-wrap {
        margin-right: 8px;
      }

      .features__list-title {
        font-size: 20px;
        line-height: 28px;
        width: 390px;
      }

      .features__item {
        width: 364px;

        &:last-child {
          margin-bottom: 70px;
        }
      }
    }

    &__video {
      padding: 40px 24px;
      margin-bottom: 60px;

      .video__title {
        width: auto;
      }

      .video__subtitle {
        width: auto;
      }

      .video__img {
        right: 24px;
      }
    }

    &__users {
      .users__title {
        margin-bottom: 12px;
        padding-left: 22px;
      }

      .users__subtitle {
        margin-bottom: 32px;
        padding-left: 24px;
      }

      .users__item {
        height: 477px;
      }

      .item__title {
        padding-left: 24px;
      }

      .item__desc {
        padding-left: 24px;
      }
    }

    &__with-us {
      padding: 33px 24px;
      margin-bottom: 60px;

      &.sellers {
        margin-bottom: 60px;
      }

      .with-us__title {
        width: auto;
        margin-right: 60px;
        white-space: nowrap;
      }

      .with-us__list {
        flex: 1;
      }

      .with-us__item {
        height: 39px;
      }

      .btn {
        padding-right: 0;
        margin-right: 56px;
        margin-left: auto;
      }

      &.sellers {
        .with-us__item {
          height: 24px;

          &:last-child {
            margin-right: 0;
          }
        }

        .btn {
          margin-right: 12px;
        }
      }
    }

    &__catalog {
      margin-bottom: 60px;

      .catalog__title {
        margin-bottom: 12px;
        padding-left: 24px;
      }

      .catalog__subtitle {
        padding-left: 24px;
      }

      .catalog__item {
        padding: 35px 24px 32px;

        .catalog__desc {
          width: 248px;
        }

        &:last-child {
          padding-right: 16px;

          .catalog__desc {
            width: 207px;
            font-size: 20px;
            line-height: 28px;
            margin-left: 128px;
            margin-right: 11px;
          }
        }
      }
    }

    &__brands {
      margin-bottom: 60px;

      .brands__title {
        margin-bottom: 32px;
        padding-left: 24px;
      }
    }

    &__news {
      margin-bottom: 80px;

      .news__title {
        padding-left: 24px;
      }

      .news__list {
        padding: 0 24px;
      }

      .news__item {
        flex: 0 0 calc(25% - 8px);
        max-width: 286px;

        .item__logo {
          height: 195px;
          max-width: 286px;
          transform: rotate(0);

          img {
            transform: rotate(0);
            height: 195px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1279px) {
  .mm-main {
    width: 100%;

    &.logged {
      .mm-main__slider {
        :deep(.carousel) {
          .carousel__slide {
            .slider__item {
              height: 500px;
              background-position-y: 138px, 0;
              padding-top: 211px;
            }

            &:nth-child(2) {
              .slider__item {
                background-position-y: 138px, 0;
                padding-top: 190px;
              }
            }
          }
        }
      }
    }

    &__slider {
      margin-top: -60px;

      :deep(.carousel) {
        .carousel__prev,
        .carousel__next {
          display: none;
        }

        .carousel__slide {
          .slider__item {
            padding-top: 100px;
            padding-left: 20px;
            height: 463px;
            background-image: url("shared/public/images/main/slider-item-1-768.png"),
            linear-gradient(107.81deg, #ececec 0%, #bcc6c3 95.12%);
            background-repeat: no-repeat;
            background-position-x: right, 0;
            background-position-y: bottom, 0;

            .item__title {
              width: 283px;
              font-size: 28px;
              line-height: 36px;
            }

            .item__subtitle {
              width: 283px;
              font-size: 14px;
              line-height: 20px;
            }
          }

          &:nth-child(2) {
            .slider__item {
              background-image: url("shared/public/images/main/slider-item-2-768.png"),
              linear-gradient(81.46deg, #02140e 18.86%, #053b3f 73.91%);
              background-repeat: no-repeat;
              background-position-x: right, 0;
              background-position-y: 60px, 0;

              .item__title {
                width: 323px;
              }

              .item__subtitle {
                width: 323px;
              }
            }
          }
        }

        .item_count {
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 12px;
        }

        .item__title {
          font-size: 28px;
          line-height: 36px;
          margin-bottom: 16px;
          width: auto;
        }

        .item__subtitle {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 99px;
          width: auto;
        }

        .item__image {
          display: none;
        }
      }
    }

    &__features {
      flex-wrap: wrap;
      background-image: none;
      padding: 32px 16px 0;
      margin: 4px;

      .features__title-wrap {
        margin-right: 0;
        flex: 0 0 100%;
        width: 100%;
      }

      .features__title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 16px;
        width: auto;
      }

      .features__subtitle {
        font-size: 14px;
        line-height: 20px;
        width: auto;
      }

      .features__list-wrap {
        margin-right: 0;
        flex: 0 0 50%;
        border-top: 1px solid $gray-200;
        border-right: 1px solid $gray-200;
        margin-left: -16px;
        padding-bottom: 32px;
        padding-left: 16px;
        padding-top: 32px;

        & + .features__list-wrap {
          margin-right: -16px;
          border-right: none;
          margin-left: 0;
          padding-left: 44px;

          .btn {
            margin-top: 0;
          }
        }
      }

      .features__list-title {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 32px;
        width: auto;
      }

      .features__item {
        width: auto;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 52px;
        }
      }
    }

    &__video {
      border-radius: 8px;
      padding: 32px 0 42px 16px;
      margin: 0 4px 48px;
      overflow: hidden;

      .video__title {
        font-size: 24px;
        line-height: 32px;
        width: auto;
        margin-bottom: 16px;
      }

      .video__subtitle {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 50px;
        width: auto;
      }

      .video__img {
        position: absolute;
        top: -15px;
        right: -180px;
      }
    }

    &__users {
      .users__title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 8px;
        padding-left: 20px;
      }

      .users__subtitle {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 24px;
        padding-left: 20px;
      }

      .users__wrap {
        flex-direction: column;
        margin: 0 4px;

        &.buyers {
          margin-bottom: 60px;

          .users__item {
            border-bottom: none;

            &:last-child {
              border-bottom-left-radius: 8px;
              border-top-right-radius: 0;
              border-bottom-right-radius: 8px;
              border-bottom: 1px solid $gray-200;
            }
          }
        }
      }

      .users__item {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-right: 16px;
        border-top: 1px solid $gray-200;
        border-right: 1px solid $gray-200;
        border-left: 1px solid $gray-200;
        flex: 0 0 120px;
        height: 120px;
        display: flex;
        align-items: center;

        &:first-child {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }

        &:last-child {
          border-radius: 0;
        }
      }

      .item__img {
        width: 96px;
        height: 96px;
        margin-bottom: 0;
        margin-right: 12px;
        flex-shrink: 0;
      }

      .item__title {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
        padding-left: 0;
      }

      .item__desc {
        font-size: 14px;
        line-height: 20px;
        padding-left: 0;
      }
    }

    &__with-us {
      display: flex;
      flex-direction: column;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      align-items: flex-start;
      padding: 24px 16px 20px;
      margin-bottom: 48px;
      margin-left: 4px;
      margin-right: 4px;

      .with-us__title {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;
        width: auto;
        margin-right: 0;
      }

      .with-us__list {
        gap: unset;
        background: $white;
        border: 1px solid $gray-200;
        border-radius: 8px;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        height: 87px;
        padding: 12px 20px;
        margin-bottom: 20px;
      }

      .with-us__item {
        height: 41px;
        flex-shrink: 0;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }

        &::before {
          content: "";
          height: 64px;
          width: 1px;
          background: $gray-200;
          display: block;
          position: absolute;
          top: -12px;
          right: -30px;
        }

        &:first-child {
          height: 34px;
        }

        &:last-child {
          margin-right: 0;

          &::before {
            display: none;
          }
        }
      }

      &.sellers {
        margin-bottom: 60px;

        .with-us__item {
          height: 29px;

          &:first-child {
            height: 19px;
          }

          &:last-child {
            height: 19px;
            margin-right: 0;
          }
        }
      }

      .btn {
        background: linear-gradient(107.81deg, #50d04d 0%, #00a77b 95.12%);
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin-left: unset;
        width: 100%;
        justify-content: center;
        color: $white;
        height: 40px;

        :deep(svg) {
          display: none;
        }
      }
    }

    &__catalog {
      margin-bottom: 48px;

      .catalog__title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 8px;
        padding-left: 20px;
      }

      .catalog__subtitle {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 24px;
        padding-left: 20px;
      }

      .catalog__list {
        margin-left: 4px;
        margin-right: 4px;
      }

      .catalog__item {
        flex: 0 0 50%;
        border-right: 1px solid $gray-200;
        border-bottom: 1px solid $gray-200;
        font-size: 16px;
        line-height: 24px;
        padding: 32px 16px;

        img {
          margin-right: 32px;
          width: 80px;
        }

        .catalog__desc {
          width: auto;
        }

        &:first-child:last-child {
          border-radius: 8px;
        }

        &:first-child {
          border-top-left-radius: 8px;
        }

        &:last-child {
          padding: 0;

          img {
            height: 101px;
            position: absolute;
            top: 0;
            left: 0;
          }

          .catalog__desc {
            margin-left: 128px;
            width: auto;
            font-size: 16px;
            line-height: 24px;
          }

          .btn {
            display: none;
          }
        }
      }
    }

    &__brands {
      margin-bottom: 48px;

      .brands__title {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        margin-bottom: 24px;
        padding-left: 20px;
      }
    }

    &__news {
      margin-bottom: 48px;
      margin-left: 4px;
      margin-right: 4px;

      .news__title {
        font-size: 24px;
        line-height: 32px;
        padding-left: 20px;
        margin-bottom: 24px;
      }

      .news__list {
        gap: 24px;
        flex-wrap: wrap;
        padding: 0 12px 0 20px;
      }

      .news__item {
        flex: 0 0 calc(50% - 8px);
        max-width: 344px;

        .item__logo {
          height: 233px;
          margin-bottom: 16px;
          max-width: 100%;

          img {
            height: 233px;
          }
        }

        .item__title {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 8px;
        }

        .item__name {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .mm-main__catalog {
    .catalog__item {
      &:nth-child(2n + 1) {
        border-left: 1px solid $category-cards-border-c;
        padding-left: 16px;
        padding-right: 12px;
      }

      &:nth-child(2n + 2) {
        padding-left: 12px;
        padding-right: 16px;
      }

      &:nth-child(-n + 2) {
        border-top: 1px solid $category-cards-border-c;
      }

      &:nth-child(2n):last-child,
      &:nth-child(2n):nth-last-child(2) {
        border-bottom-right-radius: 8px;
      }

      &:nth-child(2) {
        border-top-right-radius: 8px;
      }

      &:nth-child(2n-1):last-child,
      &:nth-child(2n-1):nth-last-child(2) {
        border-bottom-left-radius: 8px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .mm-main {
    .hidden-mobile {
      display: none;
    }

    &__slider {
      margin-top: -60px;

      :deep(.carousel) {
        .carousel__slide {
          .item_count {
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 12px;
          }

          .item__title {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 16px;
          }

          .item__subtitle {
            width: auto;
          }

          .slider__item {
            padding-top: 200px;
            height: 560px !important;
            padding-left: 20px;
            background-image: url("shared/public/images/main/slider-item-1-360.png"),
            linear-gradient(107.81deg, #ececec 0%, #bcc6c3 95.12%);
            background-position-x: right, 0;
            background-position-y: 60px, 0;

            .item__title {
              width: 283px;
            }

            .item__subtitle {
              width: 283px;
            }
          }

          .btn {
            bottom: 90px;
          }

          .item__caption {
            bottom: 40px;
          }

          &:nth-child(2) {
            .slider__item {
              background-image: url("shared/public/images/main/slider-item-2-360.png"),
              linear-gradient(81.46deg, #02140e 18.86%, #053b3f 73.91%);
              background-position-x: 0, 0;
              background-position-y: 60px, 0;
              background-size: cover;

              .item__title {
                width: 323px;
              }

              .item__subtitle {
                width: 323px;
              }
            }
          }
        }
      }
    }

    &__features {
      flex-wrap: wrap;
      background-image: none;
      padding: 24px 16px 0;
      margin: 4px;

      .features__title-wrap {
        margin-right: 0;
      }

      .features__title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 16px;
        width: auto;
      }

      .features__subtitle {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 24px;
        width: auto;
      }

      .features__list-wrap {
        margin-right: -16px;
        flex: 1;
        border-top: 1px solid $gray-200;
        border-right: none;
        margin-left: -16px;
        padding-bottom: 32px;
        padding-left: 16px;
        padding-top: 32px;

        .btn {
          margin-top: 32px;
        }

        & + .features__list-wrap {
          margin-right: -16px;
          border-right: none;
          margin-left: -16px;
          padding-left: 16px;

          .btn {
            margin-top: 32px;
          }
        }
      }

      .features__list-title {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 32px;
        width: auto;
      }

      .features__item {
        width: auto;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__video {
      padding: 24px 16px 271px;

      .btn {
        display: none;
      }

      .video__subtitle {
        margin-bottom: 0;
      }

      .video__img {
        position: absolute;
        bottom: 0;
        top: unset;
        right: unset;
        left: 0;
        height: 100%;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    &__users {
      .users__item {
        padding-top: 12px;
        padding-bottom: 12px;
        border-top: 1px solid $gray-200;
        border-right: 1px solid $gray-200;
        border-left: 1px solid $gray-200;
        flex: auto;
        height: auto;
        display: flex;
        padding-right: 16px;

        &:first-child {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }

        &:last-child {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }

      .item__img {
        margin-right: 4px;
      }

      .item__title {
        margin-bottom: 8px;
      }

      .item__desc {
        font-size: 14px;
        line-height: 20px;
        padding-left: 0;
      }
    }

    &__with-us {
      overflow: hidden;

      .with-us__title {
        margin-bottom: 17px;
      }

      .with-us__list {
        width: 100%;
        justify-content: flex-start;
        height: auto;
        padding: 0;
        flex-wrap: wrap;
        margin-right: 0;
      }

      .btn {
        margin-right: 0;
      }

      .with-us__item {
        flex: 0 0 50%;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;

        &:first-child {
          order: 4;

          img {
            width: 104px;
            height: 22px;
          }
        }

        &:nth-child(2) {
          order: 3;

          img {
            width: 125px;
            height: 29px;
          }

          &::before {
            content: "";
            height: 64px;
            width: 1px;
            background: $gray-200;
            display: block;
            position: absolute;
            top: unset;
            bottom: 8px;
            right: 0;
          }
        }

        &:nth-child(3) {
          order: 1;

          img {
            width: 90px;
            height: 35px;
          }

          &::before {
            content: "";
            height: 64px;
            width: 1px;
            background: $gray-200;
            display: block;
            position: absolute;
            top: 8px;
            bottom: 0;
            right: 0;
          }

          &::after {
            content: "";
            height: 1px;
            width: 140px;
            background: $gray-200;
            display: block;
            position: absolute;
            top: unset;
            bottom: 0;
            right: unset;
          }
        }

        &:nth-child(4) {
          order: 2;

          img {
            width: 74px;
            height: 39px;
          }

          &::before {
            content: "";
            height: 1px;
            width: 140px;
            background: $gray-200;
            display: block;
            position: absolute;
            top: unset;
            bottom: 0;
            right: unset;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &.sellers {
        margin-bottom: 60px;

        .btn {
          margin-right: 0;
        }

        .with-us__item {
          height: 80px;

          &:first-child {
            order: 1;
            height: 80px;

            img {
              width: 102px;
              height: 22px;
            }

            &::before {
              content: "";
              height: 64px;
              width: 1px;
              background: $gray-200;
              display: block;
              position: absolute;
              top: 8px;
              bottom: 0;
              right: 0;
            }

            &::after {
              content: "";
              height: 1px;
              width: 140px;
              background: $gray-200;
              display: block;
              position: absolute;
              top: unset;
              bottom: 0;
              right: unset;
            }
          }

          &:nth-child(2) {
            order: 2;

            img {
              width: 96px;
              height: 20px;
            }

            &::before {
              content: "";
              height: 1px;
              width: 140px;
              background: $gray-200;
              display: block;
              position: absolute;
              top: unset;
              bottom: 0;
              right: unset;
            }
          }

          &:nth-child(3) {
            order: 3;

            img {
              width: 124px;
              height: 22px;
            }

            &::before {
              content: "";
              height: 64px;
              width: 1px;
              background: $gray-200;
              display: block;
              position: absolute;
              top: unset;
              bottom: 8px;
              right: 0;
            }

            &::after {
              display: none;
            }
          }

          &:nth-child(4) {
            order: 4;
            height: 80px;

            img {
              width: 61px;
              height: 17px;
            }

            &::before {
              display: none;
            }
          }
        }
      }
    }

    &__catalog {
      margin-bottom: 48px;

      .catalog__item {
        flex: 0 0 100%;
        border-left: 1px solid $gray-200;
        border-right: 1px solid $gray-200;
        border-bottom: 1px solid $gray-200;
        padding: 20px;

        img {
          margin-right: 20px;
          width: 60px;
        }

        &:first-child:last-child {
          border-radius: 8px;
        }

        &:first-child {
          border-top: 1px solid $gray-200;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }

        &:last-child {
          padding: 0;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          flex: 0 0 calc(100% + 2px);
          height: 120px;

          img {
            height: 120px;
            width: 94px;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }

    &__brands {
      .brands__title {
        margin-bottom: 24px;
      }
    }

    &__news {
      .news__title {
        margin-bottom: 24px;
        flex-wrap: wrap;
        gap: 16px;

        .btn.btn-text {
          font-size: 14px;
          line-height: 20px;
        }
      }

      .news__list {
        gap: 4px;
      }

      .news__item {
        flex: 0 0 calc(50% - 8px);
        max-width: 156px;

        &:first-child {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }

        &:nth-child(2) {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }

        .item__title {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
        }

        .item__logo {
          height: 105px;
          margin-bottom: 12px;

          img {
            height: 105px;
            border-radius: 40px 0;
          }

          &.no-photo {
            border-radius: 40px 0;
          }
        }
      }
    }
  }
}
</style>
