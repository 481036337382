import { IMainSlideItem } from 'models/mainSlideItem.model';

export const MAIN_SLIDE_ITEMS: Array<IMainSlideItem> = [
  {
    title: 'B2B-маркетплейс для вашего бизнеса',
    subtitle: 'Инновационная для российского рынка платформа быстрых корпоративных закупок',
    buttonText: 'Смотреть видеопрезентацию',
    img: 'images/main/slider-item-1.png',
    isDarkSlide: false,
  },
  {
    title: 'Станьте поставщиком для крупнейших компаний России',
    subtitle: 'Откройте прямой доступ к самым надежным и крупным корпоративным клиентам страны',
    buttonText: 'Стать поставщиком',
    img: 'images/main/slider-item-2.png',
    isDarkSlide: true,
  },
].map((item, index) => ({ ...item, id: index + 1 }));

